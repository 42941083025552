var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"approval-owner-modal",attrs:{"hide-footer":"","titleHtml":_vm.modalTitle}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Người duyệt theo'}},[_c('b-form-select',{staticClass:"select-style",attrs:{"options":[
            {
              id: 1,
              name: 'Chức vụ',
            },
            {
              id: 2,
              name: 'Đính danh',
            },
            {
              id: 3,
              name: 'Tự động duyệt',
            },
          ],"size":"sm","value-field":"id","text-field":"name"},on:{"change":_vm.onChangeOwnerType},model:{value:(_vm.configDetailModel.ownerType),callback:function ($$v) {_vm.$set(_vm.configDetailModel, "ownerType", $$v)},expression:"configDetailModel.ownerType"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"disabled":_vm.ownerDisabled}},[_c('label',[_vm._v("Người duyệt")]),_c('vue-multi-select',{attrs:{"options":_vm.ownerOptions,"placeholder":"Chọn người duyệt","option-label":"name"},model:{value:(_vm.configDetailModel.owners),callback:function ($$v) {_vm.$set(_vm.configDetailModel, "owners", $$v)},expression:"configDetailModel.owners"}})],1)],1)],1),_c('b-button',{staticStyle:{"fontweight":"600","width":"100px"},attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.onSave}},[_vm._v("Lưu")]),_c('b-button',{staticStyle:{"margin-left":"10px","font-weight":"600","width":"70px"},attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.hideModal}},[_vm._v(" Hủy ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }