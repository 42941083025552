<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin cấu hình duyệt chi'">
      <template v-slot:preview>
        <b-row>
          <b-col md="3">
            <b-form-group :label="'Tên cấu hình'">
              <b-form-input
                class="input-style"
                size="sm"
                type="text"
                v-model="mainModel.name"
                placeholder="Tên cấu hình"
                :disabled="mainModel.disabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="'Số bước'">
              <b-form-input
                class="input-style text-right"
                size="sm"
                type="text"
                v-model="mainModel.numOfStep"
                placeholder="Nhập số bước"
                v-mask="currency"
                :disabled="mainModel.disabled"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group :label="'Loại chi'">
              <b-form-select
                class="select-style"
                v-model="mainModel.approvalType"
                :options="listType"
                size="sm"
                value-field="id"
                text-field="name"
                :disabled="!!mainModel.id"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group :label="'Trạng thái'">
              <b-form-select
                class="select-style"
                v-model="mainModel.isActive"
                :options="[
                  {
                    id: 1,
                    name: 'Hoạt động',
                  },
                  {
                    id: 0,
                    name: 'Không hoạt động',
                  },
                ]"
                size="sm"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <h4>Chi tiết cấu hình</h4>
        <b-button
          class="mb-4 font-weight-bolder"
          @click="addConfigDetail"
          size="sm"
          variant="primary"
          v-if="!mainModel.disabled"
        >
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
        </b-button>
        <b-table
          :fields="fields"
          :items="mainModel.details"
          v-if="renderComponent"
          hover
          bordered
        >
          <template v-slot:cell(amount1)="row">
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="row.item.amount1"
              v-mask="currency"
              :disabled="mainModel.disabled"
            ></b-input>
          </template>
          <template v-slot:cell(operator1)="row">
            <b-form-select
              v-model="row.item.operator1"
              size="sm"
              :disabled="mainModel.disabled"
            >
              <b-form-select-option value="1"> -- </b-form-select-option>
              <b-form-select-option value="2"> &lt; </b-form-select-option>
              <b-form-select-option value="4"> &le; </b-form-select-option>
            </b-form-select>
          </template>
          <template v-slot:cell(amount2)="row">
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="row.item.amount2"
              v-mask="currency"
              :disabled="mainModel.disabled"
            ></b-input>
          </template>
          <template v-slot:cell(gt)="row">
            <p class="font-weight-bold">GT</p>
          </template>
          <template v-slot:cell(operator2)="row">
            <b-form-select
              v-model="row.item.operator2"
              size="sm"
              :disabled="mainModel.disabled"
            >
              <b-form-select-option value="1"> -- </b-form-select-option>
              <b-form-select-option value="2"> &lt; </b-form-select-option>
              <b-form-select-option value="4"> &le; </b-form-select-option>
            </b-form-select>
          </template>
          <template v-slot:cell(step)="row">
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="row.item.step"
              v-mask="currency"
              :disabled="mainModel.disabled"
            ></b-input>
          </template>
          <template v-slot:cell(configDetail)="row">
            <div
              v-if="!mainModel.disabled"
              class="productCode text-center"
              @click="showConfigDetailModal(row.item)"
              style="cursor: pointer; color: blue"
            >
              <span v-text="'Người duyệt'"></span>
              <br>
              <span v-if="row.item.ownerNames.length" v-text="row.item.ownerNames" style="color: gray"></span>
            </div>
            <span v-else v-text="row.item.ownerNames" class="text-left"></span>
          </template>
          <template v-slot:cell(actions)="row">
            <v-icon
              small
              class="text-danger"
              @click="showDeleteAlert(row.item)"
              v-b-tooltip
              title="Xóa"
              :disabled="mainModel.disabled"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>
        <div class="mb-5 mt-5">
          <b-button
            v-bind:style="btnCreate"
            size="sm"
            variant="primary"
            class="font-weight-bolder mr-4"
            @click="onSubmit"
            v-if="isShowSubmit()"
          >
            Lưu
          </b-button>
          <b-button
            v-bind:style="btnCreate"
            size="sm"
            variant="secondary"
            class="font-weight-bolder"
            @click="onBack"
          >
            Hủy
          </b-button>
        </div>
        <ApprovalOwnerModal
          ref="approval-owner-modal"
          v-on:saveConfigDetail="saveConfigDetail"
          :mode="mainModel.mode"
          v-if="renderComponent"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import timeUtils from '@/utils/date';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import { reject, max, min } from 'lodash';
import { v4 } from 'uuid';
import ApprovalOwnerModal from '../../components/approval-configs/ApprovalOwnerModal';
import {
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
  currencyMask,
} from '@/utils/common';
import { APPROVAL_TYPE } from '@/utils/constants';
import { checkPermission } from '@/utils/saveDataToLocal';

export default {
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      currency: currencyMask,
      mainModel: {
        id: null,
        name: '',
        approvalType: 1,
        numOfStep: 1,
        isActive: 1,
        details: [],
        mode: 'create',
        disabled: false,
      },
      dpConfigs: timeUtils.DP_CONFIG,
      listType: APPROVAL_TYPE,
      fields: [
        {
          key: 'amount1',
          label: 'Giá trị 1',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'operator1',
          label: 'Toán tử 1',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'gt',
          label: 'GT',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'operator2',
          label: 'Toán tử 2',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amount2',
          label: 'Giá trị 2',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'step',
          label: 'Thứ tự bước',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'configDetail',
          label: 'Người duyệt',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
      ],
      renderComponent: true,
      onSubmiting: false,
    };
  },
  components: {
    KTCodePreview,
    ApprovalOwnerModal,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình duyệt chi', route: '/approval-configs' },
      { title: 'Thông tin cấu hình duyệt chi' },
    ]);
    if (this.$route.query.id) {
      this.mainModel.id = this.$route.query.id;
      this.mainModel.mode = 'update';
      this.getInfoById();
    } else {
      this.renderComponent = true;
    }
  },
  methods: {
    checkPermission,
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa chi tiết!',
        text: 'Bạn có chắc muốn xóa chi tiết này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = reject(this.mainModel.details, (detail) => {
            return detail.id == item.id;
          });
        }
      });
    },
    addConfigDetail() {
      this.mainModel.details.push({
        id: v4(),
        amount1: 0,
        operator1: 1,
        gt: 'GT',
        amount2: 0,
        operator2: 1,
        ownerType: 1,
        owners: [],
        ownerNames: [],
        step: 1,
      });
    },
    showConfigDetailModal(configDetail) {
      this.$refs['approval-owner-modal'].showModal(configDetail);
    },
    saveConfigDetail(saveConfigDetail) {
      const foundedIndexDetail = this.mainModel.details.findIndex(
        (detail) => detail.id === saveConfigDetail.id
      );
      if (foundedIndexDetail < 0) {
        return makeToastFaile('Điều kiện duyệt không tồn tại!');
      }
      this.mainModel.details[foundedIndexDetail].ownerType =
        saveConfigDetail.ownerType;
      this.mainModel.details[foundedIndexDetail].owners =
        saveConfigDetail.owners;
      const ownerNames = saveConfigDetail.owners.map((owner) => owner.name);
      this.mainModel.details[foundedIndexDetail].ownerNames = `( ${ownerNames.join(', ')} )` 
    },
    onSubmit() {
      const details = this.mainModel.details.map((payloadDetail) => {
        const ownerIds = payloadDetail.owners.map((owner) => owner.id);
        return {
          id: payloadDetail.id,
          operator1: Number(payloadDetail.operator1),
          amount1: unMaskPrice(payloadDetail.amount1),
          operator2: Number(payloadDetail.operator2),
          amount2: unMaskPrice(payloadDetail.amount2),
          step: unMaskPrice(payloadDetail.step),
          ownerType: Number(payloadDetail.ownerType),
          ownerIds: ownerIds,
          deleted: 0,
        };
      });
      const payload = {
        id: this.mainModel.id,
        name: this.mainModel.name.trim(),
        approvalType: this.mainModel.approvalType,
        numOfStep: Number(this.mainModel.numOfStep),
        details: details,
        isActive: this.mainModel.isActive,
      };
      if (!payload.name) {
        return makeToastFaile('Vui lòng nhập tên cấu hình duyệt chi!');
      }
      if (!payload.approvalType) {
        return makeToastFaile('Vui lòng chọn loại cấu hình duyệt chi!');
      }
      if (!payload.numOfStep || payload.numOfStep < 1) {
        return makeToastFaile('Vui lòng nhập số bước duyệt chi hợp lệ!');
      }
      if (!payload.details || !payload.details.length) {
        return makeToastFaile('Vui lòng nhập điều kiện duyệt chi!');
      }
      const isValidDetail = this.validateStepDetails(
        details,
        payload.numOfStep
      );
      if (!isValidDetail) {
        const rangeStep =
          payload.numOfStep === 1 ? '[1]' : `[1-${payload.numOfStep}]`;
        return makeToastFaile(
          `Vui lòng nhập thứ tự bước hợp lệ: ${rangeStep}!`
        );
      }
      const nonOwnerStep = details.find((detail) => !detail.ownerIds.length);
      if (nonOwnerStep) {
        return makeToastFaile(
          `Vui lòng chọn người duyệt cho bước: ${nonOwnerStep.step}!`
        );
      }
      if (!payload.id) {
        this.onCallApiUpsert(payload, 'post');
        return;
      }
      this.onCallApiUpsert(payload, 'put');
    },
    onCallApiUpsert(payload, method) {
      if (this.onSubmiting) {
        return;
      }
      this.onSubmiting = true;
      ApiService[method]('v2/approval-configs', payload)
        .then(({ data }) => {
          if (data.status === 200) {
            makeToastSuccess(data.message);
            this.$router.push({
              path: '/approval-configs',
            });
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
    onBack() {
      this.$router.push({
        name: 'list-approval-config',
      });
    },
    getInfoById() {
      ApiService.get(`v2/approval-configs/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel = data.data;
          this.mainModel.details = data.data.details.map((detail) => {
            const ownerNames = detail.owners.map((owner) => owner.name);
            return {
              ...detail,
              ownerNames: ownerNames.join(', '),
            };
          });
          this.renderComponent = true;
          if (!this.mainModel.disabled) {
            this.mainModel.disabled = !this.checkPermission(
              'APPROVAL_CONFIG_UPDATE'
            );
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    validateStepDetails(details, numOfStep) {
      const steps = details.map((detail) => Number(detail.step));
      const maxStep = max(steps);
      const minStep = min(steps);
      if (minStep !== 1 || maxStep !== numOfStep) {
        return false;
      }
      return true;
    },
    isShowSubmit() {
      if (this.mainModel.id) {
        return this.checkPermission('APPROVAL_CONFIG_UPDATE');
      }
      return this.checkPermission('APPROVAL_CONFIG_INSERT');
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
