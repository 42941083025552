<template>
  <b-modal ref="approval-owner-modal" hide-footer :titleHtml="modalTitle">
    <b-row>
      <b-col md="6">
        <b-form-group :label="'Người duyệt theo'">
          <b-form-select
            v-model="configDetailModel.ownerType"
            :options="[
              {
                id: 1,
                name: 'Chức vụ',
              },
              {
                id: 2,
                name: 'Đính danh',
              },
              {
                id: 3,
                name: 'Tự động duyệt',
              },
            ]"
            size="sm"
            class="select-style"
            value-field="id"
            text-field="name"
            @change="onChangeOwnerType"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :disabled="ownerDisabled">
          <label>Người duyệt</label>
          <vue-multi-select
            v-model="configDetailModel.owners"
            :options="ownerOptions"
            placeholder="Chọn người duyệt"
            option-label="name"
          ></vue-multi-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      style="fontweight: 600; width: 100px"
      variant="primary"
      size="sm"
      @click="onSave"
      >Lưu</b-button
    >
    <b-button
      style="margin-left: 10px; font-weight: 600; width: 70px"
      variant="secondary"
      size="sm"
      @click="hideModal"
    >
      Hủy
    </b-button>
  </b-modal>
</template>

<script>
import VueMultiSelect from 'vue-simple-multi-select';
import ApiService from '@/core/services/api.service';
import { JOB_TITLE } from '@/utils/constants';
import { cloneDeep, makeToastFaile } from '@/utils/common';

export default {
  props: ['mode'],
  data() {
    return {
      modalTitle: '',
      configDetailModel: {},
      renderComponent: false,
      ownerOptions: [],
      ownerDisabled: false,
      configDetail: {},
    };
  },
  components: {
    VueMultiSelect,
  },
  methods: {
    showModal: async function (configDetail) {
      this.configDetail = configDetail;
      await this.fetchEmployee();
      this.configDetailModel = cloneDeep(configDetail);
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Người duyệt cho: <a class='text-primary'>#Bước 1</a></span>
              </div>`;
      this.$refs['approval-owner-modal'].show();
    },
    fetchEmployee: async function () {
      this.employeeOptions = [];
      ApiService.get('/employees/get-list-filter').then((response) => {
        this.employeeOptions = response.data.data.map((element) => {
          return {
            id: element.id,
            name: element.name,
          };
        });
        this.onChangeOwnerType();
      });
    },
    onChangeOwnerType() {
      if (this.configDetailModel.ownerType === 3) {
        this.ownerOptions = [];
        this.configDetailModel.owners = [
          {
            id: -1,
            name: 'Tự động duyệt',
          },
        ];
        this.ownerDisabled = true;
        return;
      }
      if (this.configDetailModel.ownerType === 1) {
        this.ownerOptions = JOB_TITLE.filter((jobtitle) => jobtitle.id);
      }
      if (this.configDetailModel.ownerType === 2) {
        this.ownerOptions = this.employeeOptions;
      }
      this.ownerDisabled = false;
      this.configDetailModel.owners = this.configDetail.owners;
    },
    onSave() {
      if (
        !this.configDetailModel.owners ||
        !this.configDetailModel.owners.length
      ) {
        return makeToastFaile('Vui lòng chọn người duyệt!');
      }
      this.$emit('saveConfigDetail', this.configDetailModel);
      this.hideModal();
    },
    hideModal: function () {
      this.$refs['approval-owner-modal'].hide();
    },
  },
};
</script>
